
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* Default background color of <body />...etc */
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;
 /* Muted backgrounds such as <TabsList />, <Skeleton /> and <Switch /> */
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
    /* Background color for popovers such as <DropdownMenu />, <HoverCard />, <Popover /> */
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;
 /* Default border color */
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
 /* Background color for <Card /> */
    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;
 /* Primary colors for <Button /> */
    --primary: 214.4, 80.8%, 55%;
    /* --primary: 108.2 87.4% 51.2%; */
    --primary-foreground: 210 60% 98%;
 
    /* --secondary: 210 40% 96.1%; */
    --secondary: 215.6, 35%, 50%;
    --secondary-foreground: 222.2 47.4% 11.2%;
/* To add new colors here and in tailwind.config */
    /* --warning: 38 92% 50%;
    --warning-foreground: 48 96% 89%; */
 /* Used for accents such as hover effects on <DropdownMenuItem>, <SelectItem>...etc */
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;
 
    --ring: 215 20.2% 65.1%;
 
    --radius: 0.5rem;
  }
 
  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;
 
    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;
 
    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;
 
    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;
 
    --border: 216 34% 17%;
    --input: 216 34% 17%;
 
    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;
 
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;
 
    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;
 
    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;
 
    --ring: 216 34% 17%;
 
    --radius: 0.5rem;
  }
  * {
    @apply tw-border-border;
  }
  body {
    @apply tw-bg-background tw-text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}
 
/* @layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*     --dark-slate-grey: #2e3642;
    --blue-logo: #005677;
    --slate-blue: #484dff;
    --orange-logo: #ff9015;
    --white: white;
    --slate-grey: #546681;
    --yellow: #fff5ea; */

.cool-gradient-button {
  /* background: linear-gradient(135deg, #ff9015, #005677); */
  background: linear-gradient(135deg, #fff5ea, #ff9015);

  color: #4d5969 !important; /* Ensures text color applies */
  border: none !important; /* Ensures no border is applied */
  font-weight: bold;
  position: relative;
  overflow: hidden;
  z-index: 0;
  transition: all 0.3s ease;
}

.cool-gradient-button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #ff9015, #fff5ea);
  z-index: -1;
  transition: opacity 0.3s ease;
  opacity: 0;
}

.cool-gradient-button:hover::after {
  opacity: 1;
}

.cool-gradient-button:focus {
  outline: none;
}
.grade-container {
  display: flex;
  align-items: center;
}

.grade-box {
  width: 40px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
}

.grade-box:nth-child(1) {
  background-color: #008c3a;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  color: #88d673;
}

.grade-box:nth-child(2) {
  background-color: #9acd32;
  color: #c7e2d4;
}

.grade-box:nth-child(3) {
  background-color: #ffd700;
  color: #f9e14b;
}

.grade-box:nth-child(4) {
  background-color: #ffa500;
  color: #f8dc4a;
}

.grade-box:nth-child(5) {
  background-color: #e60000;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  color: #f09837;
}

.highlight {
  border: 3px solid white;
  width: 53px;
  height: 95px;
  border-radius: 20px !important;
  font-size: 50px;
  font-weight: bold;
  color: white !important;
}
.step-menu {
  list-style-type:none;
  display: flex;
  /* width:725px; */
  margin:0 auto;
  margin-left: -60px;
  margin-right: -40px;
  }
  .step-menu li {
    /* flex: 1; */
    width: 300px;
    box-sizing: border-box; 
    float: left;
    background: white;
    height: 84px;
    text-align: center;
    /* padding: 15px 15px 15px 50px; */
    position: relative;
    margin-left: 10px;
    /* border: 2px solid black; */
    border-right: none;
    border-left: none;
    }
    .step-menu li:before {
      content: '';
      position: absolute;
      border: 42px solid transparent;
      border-left-color: rgba(237,242,249,0.96);
      top:0px;
      right: -84px;
    }
    .step-menu li:after {
      content: '';
      position: absolute;
      border: 42px solid transparent;
      border-left-color: white;
      top:0px;
      right: -78px;
    }
    .step-menu li:first-child {
      z-index:600;
      margin-left: 0;
      padding-left: 30px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    .step-menu li:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    .step-menu li:nth-child(2) {
      z-index:500;
    }
    .step-menu li:nth-child(3) {
      z-index:400;
    }
    .step-menu li:nth-child(4) {
      z-index:300;
    }
/* 
    .step-menu li:last-child:after,
    .step-menu li:last-child:before {
      border: none;

    } */

    /* Hover effect */
.step-menu li:hover {
  transform: scale(1.05); /* Enlarge step on hover */
  /* z-index: 700;  */
}

/* Smooth scaling */
.step-menu li {
  transition: transform 0.3s ease; /* Smooth transition */
}



.icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex:1;
}

.icon-wrapper:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 14%;
  right: -60%;
  width: 100%;
  height: 2px;
  background-color: #ccc; /* Change the color as needed */
}
/* TIMELINE */

.timeline-item {
  position: relative;
}

.timeline-item::before {
  content: '';
  position: absolute;
  left: 29px; /* Adjust to align with icons */
  width: 2px;
  /* background-color: #ccc; */
  border-left: 2px dashed #ccc;
  top: 0;
  bottom: 0;
  z-index: 0; /* Ensure the line appears behind the icon */
}

.timeline-item.first-item::before {
  top: 43px; /* Start line from middle of the first icon */
}

.timeline-item.last-item::before {
  bottom: 53px; /* End line at middle of the last icon */
}

.timeline-icon {
  position: relative;
  z-index: 1; /* Ensure the icon appears above the line */
}
