.card {
  position: relative;
  width: 500px;
  height: 500px;
  margin: 20px;
  background: #FFF;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  transform: rotateX(0) rotateY(0);
  transform-style: preserve-3d;
  transition: transform 0.5s ease, box-shadow 0.5s ease;
  cursor: pointer;
}

.card:hover {
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
  transform: rotateX(-10deg) rotateY(10deg);
}

.card__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  transition: background 0.3s ease;
}

.card__heading {
  margin-top: 100px;
  text-align: center;
}
