*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  ;
}

:root {
  /* Default background color of <body />...etc */
  --background: 0 0% 100%;
  --foreground: 222.2 47.4% 11.2%;
  /* Muted backgrounds such as <TabsList />, <Skeleton /> and <Switch /> */
  --muted: 210 40% 96.1%;
  --muted-foreground: 215.4 16.3% 46.9%;
  /* Background color for popovers such as <DropdownMenu />, <HoverCard />, <Popover /> */
  --popover: 0 0% 100%;
  --popover-foreground: 222.2 47.4% 11.2%;
  /* Default border color */
  --border: 214.3 31.8% 91.4%;
  --input: 214.3 31.8% 91.4%;
  /* Background color for <Card /> */
  --card: 0 0% 100%;
  --card-foreground: 222.2 47.4% 11.2%;
  /* Primary colors for <Button /> */
  --primary: 214.4, 80.8%, 55%;
  /* --primary: 108.2 87.4% 51.2%; */
  --primary-foreground: 210 60% 98%;
  /* --secondary: 210 40% 96.1%; */
  --secondary: 215.6, 35%, 50%;
  --secondary-foreground: 222.2 47.4% 11.2%;
  /* To add new colors here and in tailwind.config */
  /* --warning: 38 92% 50%;
    --warning-foreground: 48 96% 89%; */
  /* Used for accents such as hover effects on <DropdownMenuItem>, <SelectItem>...etc */
  --accent: 210 40% 96.1%;
  --accent-foreground: 222.2 47.4% 11.2%;
  --destructive: 0 100% 50%;
  --destructive-foreground: 210 40% 98%;
  --ring: 215 20.2% 65.1%;
  --radius: 0.5rem;
}

.dark {
  --background: 224 71% 4%;
  --foreground: 213 31% 91%;
  --muted: 223 47% 11%;
  --muted-foreground: 215.4 16.3% 56.9%;
  --accent: 216 34% 17%;
  --accent-foreground: 210 40% 98%;
  --popover: 224 71% 4%;
  --popover-foreground: 215 20.2% 65.1%;
  --border: 216 34% 17%;
  --input: 216 34% 17%;
  --card: 224 71% 4%;
  --card-foreground: 213 31% 91%;
  --primary: 210 40% 98%;
  --primary-foreground: 222.2 47.4% 1.2%;
  --secondary: 222.2 47.4% 11.2%;
  --secondary-foreground: 210 40% 98%;
  --destructive: 0 63% 31%;
  --destructive-foreground: 210 40% 98%;
  --ring: 216 34% 17%;
  --radius: 0.5rem;
}

* {
  border-color: hsl(var(--border));
}

body {
  background-color: hsl(var(--background));
  color: hsl(var(--foreground));
  font-feature-settings: "rlig" 1, "calt" 1;
}

.tw-container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem;
}

@media (min-width: 1400px) {
  .tw-container {
    max-width: 1400px;
  }
}

.tw-sr-only {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border-width: 0 !important;
}

.tw-absolute {
  position: absolute !important;
}

.tw-relative {
  position: relative !important;
}

.tw-left-2 {
  left: 0.5rem !important;
}

.tw-right-2 {
  right: 0.5rem !important;
}

.tw-right-4 {
  right: 1rem !important;
}

.tw-top-4 {
  top: 1rem !important;
}

.tw-z-50 {
  z-index: 50 !important;
}

.tw--mx-1 {
  margin-left: -0.25rem !important;
  margin-right: -0.25rem !important;
}

.tw-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.tw-my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.tw-mb-2 {
  margin-bottom: 0.5rem !important;
}

.tw-mb-4 {
  margin-bottom: 1rem !important;
}

.tw-mb-6 {
  margin-bottom: 1.5rem !important;
}

.tw-ml-1 {
  margin-left: 0.25rem !important;
}

.tw-ml-2 {
  margin-left: 0.5rem !important;
}

.tw-ml-auto {
  margin-left: auto !important;
}

.tw-mr-2 {
  margin-right: 0.5rem !important;
}

.tw-mt-8 {
  margin-top: 2rem !important;
}

.tw-line-clamp-1 {
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 1 !important;
}

.tw-flex {
  display: flex !important;
}

.tw-inline-flex {
  display: inline-flex !important;
}

.tw-grid {
  display: grid !important;
}

.tw-size-4 {
  width: 1rem !important;
  height: 1rem !important;
}

.tw-h-10 {
  height: 2.5rem !important;
}

.tw-h-2 {
  height: 0.5rem !important;
}

.tw-h-3 {
  height: 0.75rem !important;
}

.tw-h-3\.5 {
  height: 0.875rem !important;
}

.tw-h-32 {
  height: 8rem !important;
}

.tw-h-4 {
  height: 1rem !important;
}

.tw-h-6 {
  height: 1.5rem !important;
}

.tw-h-8 {
  height: 2rem !important;
}

.tw-h-9 {
  height: 2.25rem !important;
}

.tw-h-\[var\(--radix-select-trigger-height\)\] {
  height: var(--radix-select-trigger-height) !important;
}

.tw-h-full {
  height: 100% !important;
}

.tw-h-px {
  height: 1px !important;
}

.tw-max-h-96 {
  max-height: 24rem !important;
}

.tw-w-10 {
  width: 2.5rem !important;
}

.tw-w-24 {
  width: 6rem !important;
}

.tw-w-3 {
  width: 0.75rem !important;
}

.tw-w-3\.5 {
  width: 0.875rem !important;
}

.tw-w-32 {
  width: 8rem !important;
}

.tw-w-4 {
  width: 1rem !important;
}

.tw-w-6 {
  width: 1.5rem !important;
}

.tw-w-8 {
  width: 2rem !important;
}

.tw-w-9 {
  width: 2.25rem !important;
}

.tw-w-\[200px\] {
  width: 200px !important;
}

.tw-w-full {
  width: 100% !important;
}

.tw-min-w-\[8rem\] {
  min-width: 8rem !important;
}

.tw-min-w-\[var\(--radix-select-trigger-width\)\] {
  min-width: var(--radix-select-trigger-width) !important;
}

.tw-flex-1 {
  flex: 1 1 0% !important;
}

.tw-shrink-0 {
  flex-shrink: 0 !important;
}

.tw-flex-grow {
  flex-grow: 1 !important;
}

@keyframes tw-pulse {
  50% {
    opacity: .5;
  }
}

.tw-animate-pulse {
  animation: tw-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
}

.tw-cursor-default {
  cursor: default !important;
}

.tw-cursor-not-allowed {
  cursor: not-allowed !important;
}

.tw-select-none {
  -webkit-user-select: none !important;
          user-select: none !important;
}

.tw-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}

.tw-flex-col {
  flex-direction: column !important;
}

.tw-items-center {
  align-items: center !important;
}

.tw-justify-center {
  justify-content: center !important;
}

.tw-justify-between {
  justify-content: space-between !important;
}

.tw-gap-2 {
  gap: 0.5rem !important;
}

.tw-gap-4 {
  gap: 1rem !important;
}

.tw-gap-6 {
  gap: 1.5rem !important;
}

.tw-space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.375rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse)) !important;
}

.tw-overflow-hidden {
  overflow: hidden !important;
}

.tw-whitespace-nowrap {
  white-space: nowrap !important;
}

.tw-rounded-full {
  border-radius: 9999px !important;
}

.tw-rounded-lg {
  border-radius: var(--radius) !important;
}

.tw-rounded-md {
  border-radius: calc(var(--radius) - 2px) !important;
}

.tw-rounded-sm {
  border-radius: calc(var(--radius) - 4px) !important;
}

.tw-rounded-xl {
  border-radius: 0.75rem !important;
}

.tw-border {
  border-width: 1px !important;
}

.tw-border-b {
  border-bottom-width: 1px !important;
}

.tw-border-input {
  border-color: hsl(var(--input)) !important;
}

.tw-border-transparent {
  border-color: transparent !important;
}

.tw-bg-background {
  background-color: hsl(var(--background)) !important;
}

.tw-bg-card {
  background-color: hsl(var(--card)) !important;
}

.tw-bg-destructive {
  background-color: hsl(var(--destructive)) !important;
}

.tw-bg-muted {
  background-color: hsl(var(--muted)) !important;
}

.tw-bg-popover {
  background-color: hsl(var(--popover)) !important;
}

.tw-bg-primary {
  background-color: hsl(var(--primary)) !important;
}

.tw-bg-primary\/20 {
  background-color: hsl(var(--primary) / 0.2) !important;
}

.tw-bg-secondary {
  background-color: hsl(var(--secondary)) !important;
}

.tw-bg-transparent {
  background-color: transparent !important;
}

.tw-fill-current {
  fill: currentColor !important;
}

.tw-p-0 {
  padding: 0px !important;
}

.tw-p-1 {
  padding: 0.25rem !important;
}

.tw-p-4 {
  padding: 1rem !important;
}

.tw-p-6 {
  padding: 1.5rem !important;
}

.tw-px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.tw-px-2\.5 {
  padding-left: 0.625rem !important;
  padding-right: 0.625rem !important;
}

.tw-px-3 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.tw-px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.tw-px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.tw-py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.tw-py-0\.5 {
  padding-top: 0.125rem !important;
  padding-bottom: 0.125rem !important;
}

.tw-py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.tw-py-1\.5 {
  padding-top: 0.375rem !important;
  padding-bottom: 0.375rem !important;
}

.tw-py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.tw-py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.tw-pb-4 {
  padding-bottom: 1rem !important;
}

.tw-pl-2 {
  padding-left: 0.5rem !important;
}

.tw-pl-8 {
  padding-left: 2rem !important;
}

.tw-pr-2 {
  padding-right: 0.5rem !important;
}

.tw-pr-8 {
  padding-right: 2rem !important;
}

.tw-pt-0 {
  padding-top: 0px !important;
}

.tw-text-2xl {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
}

.tw-text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

.tw-text-xs {
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}

.tw-font-bold {
  font-weight: 700 !important;
}

.tw-font-medium {
  font-weight: 500 !important;
}

.tw-font-semibold {
  font-weight: 600 !important;
}

.tw-ordinal {
  --tw-ordinal: ordinal !important;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction) !important;
}

.tw-leading-none {
  line-height: 1 !important;
}

.tw-tracking-tight {
  letter-spacing: -0.025em !important;
}

.tw-tracking-widest {
  letter-spacing: 0.1em !important;
}

.tw-text-card-foreground {
  color: hsl(var(--card-foreground)) !important;
}

.tw-text-destructive-foreground {
  color: hsl(var(--destructive-foreground)) !important;
}

.tw-text-foreground {
  color: hsl(var(--foreground)) !important;
}

.tw-text-gray-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(209 213 219 / var(--tw-text-opacity)) !important;
}

.tw-text-gray-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(107 114 128 / var(--tw-text-opacity)) !important;
}

.tw-text-green-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(34 197 94 / var(--tw-text-opacity)) !important;
}

.tw-text-muted-foreground {
  color: hsl(var(--muted-foreground)) !important;
}

.tw-text-popover-foreground {
  color: hsl(var(--popover-foreground)) !important;
}

.tw-text-primary {
  color: hsl(var(--primary)) !important;
}

.tw-text-primary-foreground {
  color: hsl(var(--primary-foreground)) !important;
}

.tw-text-red-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(239 68 68 / var(--tw-text-opacity)) !important;
}

.tw-text-secondary-foreground {
  color: hsl(var(--secondary-foreground)) !important;
}

.tw-text-yellow-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(234 179 8 / var(--tw-text-opacity)) !important;
}

.tw-line-through {
  text-decoration-line: line-through !important;
}

.tw-underline-offset-4 {
  text-underline-offset: 4px !important;
}

.tw-opacity-50 {
  opacity: 0.5 !important;
}

.tw-opacity-60 {
  opacity: 0.6 !important;
}

.tw-opacity-70 {
  opacity: 0.7 !important;
}

.tw-shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.tw-shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1) !important;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.tw-shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1) !important;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.tw-shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05) !important;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.tw-outline-none {
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
}

.tw-ring-offset-background {
  --tw-ring-offset-color: hsl(var(--background)) !important;
}

.tw-transition-all {
  transition-property: all !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

.tw-transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

.tw-transition-transform {
  transition-property: transform !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

.tw-duration-200 {
  transition-duration: 200ms !important;
}

@keyframes enter {
  from {
    opacity: var(--tw-enter-opacity, 1);
    transform: translate3d(var(--tw-enter-translate-x, 0), var(--tw-enter-translate-y, 0), 0) scale3d(var(--tw-enter-scale, 1), var(--tw-enter-scale, 1), var(--tw-enter-scale, 1)) rotate(var(--tw-enter-rotate, 0));
  }
}

@keyframes exit {
  to {
    opacity: var(--tw-exit-opacity, 1);
    transform: translate3d(var(--tw-exit-translate-x, 0), var(--tw-exit-translate-y, 0), 0) scale3d(var(--tw-exit-scale, 1), var(--tw-exit-scale, 1), var(--tw-exit-scale, 1)) rotate(var(--tw-exit-rotate, 0));
  }
}

.tw-animate-in {
  animation-name: enter !important;
  animation-duration: 150ms !important;
  --tw-enter-opacity: initial !important;
  --tw-enter-scale: initial !important;
  --tw-enter-rotate: initial !important;
  --tw-enter-translate-x: initial !important;
  --tw-enter-translate-y: initial !important;
}

.tw-fade-in-0 {
  --tw-enter-opacity: 0 !important;
}

.tw-zoom-in-95 {
  --tw-enter-scale: .95 !important;
}

.tw-duration-200 {
  animation-duration: 200ms !important;
}

/* @layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
} */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*     --dark-slate-grey: #2e3642;
    --blue-logo: #005677;
    --slate-blue: #484dff;
    --orange-logo: #ff9015;
    --white: white;
    --slate-grey: #546681;
    --yellow: #fff5ea; */

.cool-gradient-button {
  /* background: linear-gradient(135deg, #ff9015, #005677); */
  background: linear-gradient(135deg, #fff5ea, #ff9015);
  color: #4d5969 !important;
  /* Ensures text color applies */
  border: none !important;
  /* Ensures no border is applied */
  font-weight: bold;
  position: relative;
  overflow: hidden;
  z-index: 0;
  transition: all 0.3s ease;
}

.cool-gradient-button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #ff9015, #fff5ea);
  z-index: -1;
  transition: opacity 0.3s ease;
  opacity: 0;
}

.cool-gradient-button:hover::after {
  opacity: 1;
}

.cool-gradient-button:focus {
  outline: none;
}

.step-menu {
  list-style-type:none;
  display: flex;
  /* width:725px; */
  margin:0 auto;
  margin-left: -60px;
  margin-right: -40px;
}

.step-menu li {
  /* flex: 1; */
  width: 300px;
  box-sizing: border-box;
  float: left;
  background: white;
  height: 84px;
  text-align: center;
  /* padding: 15px 15px 15px 50px; */
  position: relative;
  margin-left: 10px;
  /* border: 2px solid black; */
  border-right: none;
  border-left: none;
}

.step-menu li:before {
  content: '';
  position: absolute;
  border: 42px solid transparent;
  border-left-color: rgba(237,242,249,0.96);
  top:0px;
  right: -84px;
}

.step-menu li:after {
  content: '';
  position: absolute;
  border: 42px solid transparent;
  border-left-color: white;
  top:0px;
  right: -78px;
}

.step-menu li:first-child {
  z-index:600;
  margin-left: 0;
  padding-left: 30px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.step-menu li:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.step-menu li:nth-child(2) {
  z-index:500;
}

.step-menu li:nth-child(3) {
  z-index:400;
}

.step-menu li:nth-child(4) {
  z-index:300;
}

/* 
    .step-menu li:last-child:after,
    .step-menu li:last-child:before {
      border: none;

    } */

/* Hover effect */

.step-menu li:hover {
  transform: scale(1.05);
  /* Enlarge step on hover */
  /* z-index: 700;  */
}

/* Smooth scaling */

.step-menu li {
  transition: transform 0.3s ease;
  /* Smooth transition */
}

.icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex:1;
}

.icon-wrapper:not(:last-child)::after {
  content: '';
  position: absolute;
  top: 14%;
  right: -60%;
  width: 100%;
  height: 2px;
  background-color: #ccc;
  /* Change the color as needed */
}

/* TIMELINE */

.timeline-item {
  position: relative;
}

.timeline-item::before {
  content: '';
  position: absolute;
  left: 29px;
  /* Adjust to align with icons */
  width: 2px;
  /* background-color: #ccc; */
  border-left: 2px dashed #ccc;
  top: 0;
  bottom: 0;
  z-index: 0;
  /* Ensure the line appears behind the icon */
}

.timeline-item.first-item::before {
  top: 43px;
  /* Start line from middle of the first icon */
}

.timeline-item.last-item::before {
  bottom: 53px;
  /* End line at middle of the last icon */
}

.timeline-icon {
  position: relative;
  z-index: 1;
  /* Ensure the icon appears above the line */
}

.file\:tw-border-0::file-selector-button {
  border-width: 0px !important;
}

.file\:tw-bg-transparent::file-selector-button {
  background-color: transparent !important;
}

.file\:tw-text-sm::file-selector-button {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

.file\:tw-font-medium::file-selector-button {
  font-weight: 500 !important;
}

.file\:tw-text-foreground::file-selector-button {
  color: hsl(var(--foreground)) !important;
}

.hover\:tw-bg-accent:hover {
  background-color: hsl(var(--accent)) !important;
}

.hover\:tw-bg-destructive\/80:hover {
  background-color: hsl(var(--destructive) / 0.8) !important;
}

.hover\:tw-bg-destructive\/90:hover {
  background-color: hsl(var(--destructive) / 0.9) !important;
}

.hover\:tw-bg-primary\/80:hover {
  background-color: hsl(var(--primary) / 0.8) !important;
}

.hover\:tw-bg-secondary:hover {
  background-color: hsl(var(--secondary)) !important;
}

.hover\:tw-bg-secondary\/80:hover {
  background-color: hsl(var(--secondary) / 0.8) !important;
}

.hover\:tw-text-accent-foreground:hover {
  color: hsl(var(--accent-foreground)) !important;
}

.hover\:tw-underline:hover {
  text-decoration-line: underline !important;
}

.focus\:tw-bg-accent:focus {
  background-color: hsl(var(--accent)) !important;
}

.focus\:tw-text-accent-foreground:focus {
  color: hsl(var(--accent-foreground)) !important;
}

.focus\:tw-outline-none:focus {
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
}

.focus\:tw-ring-1:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.focus\:tw-ring-ring:focus {
  --tw-ring-color: hsl(var(--ring)) !important;
}

.focus-visible\:tw-outline-none:focus-visible {
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
}

.focus-visible\:tw-ring-1:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.focus-visible\:tw-ring-ring:focus-visible {
  --tw-ring-color: hsl(var(--ring)) !important;
}

.disabled\:tw-pointer-events-none:disabled {
  pointer-events: none !important;
}

.disabled\:tw-cursor-not-allowed:disabled {
  cursor: not-allowed !important;
}

.disabled\:tw-opacity-50:disabled {
  opacity: 0.5 !important;
}

.tw-peer:disabled ~ .peer-disabled\:tw-cursor-not-allowed {
  cursor: not-allowed !important;
}

.tw-peer:disabled ~ .peer-disabled\:tw-opacity-70 {
  opacity: 0.7 !important;
}

.data-\[disabled\]\:tw-pointer-events-none[data-disabled] {
  pointer-events: none !important;
}

.data-\[side\=bottom\]\:tw-translate-y-1[data-side="bottom"] {
  --tw-translate-y: 0.25rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.data-\[side\=left\]\:-tw-translate-x-1[data-side="left"] {
  --tw-translate-x: -0.25rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.data-\[side\=right\]\:tw-translate-x-1[data-side="right"] {
  --tw-translate-x: 0.25rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.data-\[side\=top\]\:-tw-translate-y-1[data-side="top"] {
  --tw-translate-y: -0.25rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

@keyframes tw-accordion-up {
  from {
    height: var(--radix-accordion-content-height);
  }

  to {
    height: 0;
  }
}

.data-\[state\=closed\]\:tw-animate-accordion-up[data-state="closed"] {
  animation: tw-accordion-up 0.2s ease-out !important;
}

@keyframes tw-accordion-down {
  from {
    height: 0;
  }

  to {
    height: var(--radix-accordion-content-height);
  }
}

.data-\[state\=open\]\:tw-animate-accordion-down[data-state="open"] {
  animation: tw-accordion-down 0.2s ease-out !important;
}

.data-\[state\=open\]\:tw-bg-accent[data-state="open"] {
  background-color: hsl(var(--accent)) !important;
}

.data-\[disabled\]\:tw-opacity-50[data-disabled] {
  opacity: 0.5 !important;
}

.data-\[state\=open\]\:tw-animate-in[data-state="open"] {
  animation-name: enter !important;
  animation-duration: 150ms !important;
  --tw-enter-opacity: initial !important;
  --tw-enter-scale: initial !important;
  --tw-enter-rotate: initial !important;
  --tw-enter-translate-x: initial !important;
  --tw-enter-translate-y: initial !important;
}

.data-\[state\=closed\]\:tw-animate-out[data-state="closed"] {
  animation-name: exit !important;
  animation-duration: 150ms !important;
  --tw-exit-opacity: initial !important;
  --tw-exit-scale: initial !important;
  --tw-exit-rotate: initial !important;
  --tw-exit-translate-x: initial !important;
  --tw-exit-translate-y: initial !important;
}

.data-\[state\=closed\]\:tw-fade-out-0[data-state="closed"] {
  --tw-exit-opacity: 0 !important;
}

.data-\[state\=open\]\:tw-fade-in-0[data-state="open"] {
  --tw-enter-opacity: 0 !important;
}

.data-\[state\=closed\]\:tw-zoom-out-95[data-state="closed"] {
  --tw-exit-scale: .95 !important;
}

.data-\[state\=open\]\:tw-zoom-in-95[data-state="open"] {
  --tw-enter-scale: .95 !important;
}

.data-\[side\=bottom\]\:tw-slide-in-from-top-2[data-side="bottom"] {
  --tw-enter-translate-y: -0.5rem !important;
}

.data-\[side\=left\]\:tw-slide-in-from-right-2[data-side="left"] {
  --tw-enter-translate-x: 0.5rem !important;
}

.data-\[side\=right\]\:tw-slide-in-from-left-2[data-side="right"] {
  --tw-enter-translate-x: -0.5rem !important;
}

.data-\[side\=top\]\:tw-slide-in-from-bottom-2[data-side="top"] {
  --tw-enter-translate-y: 0.5rem !important;
}

@media (min-width: 768px) {
  .md\:tw-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
}

@media (min-width: 1024px) {
  .lg\:tw-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
}

.\[\&\>span\]\:tw-line-clamp-1>span {
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 1 !important;
}

.\[\&\>svg\]\:tw-size-4>svg {
  width: 1rem !important;
  height: 1rem !important;
}

.\[\&\>svg\]\:tw-shrink-0>svg {
  flex-shrink: 0 !important;
}

.\[\&\[data-state\=open\]\>svg\]\:tw-rotate-180[data-state=open]>svg {
  --tw-rotate: 180deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}
